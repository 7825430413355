import ScreenWidthContainerComponent from '@wix/thunderbolt-elements/src/components/ScreenWidthContainer/viewer/ScreenWidthContainer';


const ScreenWidthContainer = {
  component: ScreenWidthContainerComponent
};


export const components = {
  ['ScreenWidthContainer']: ScreenWidthContainer
};

